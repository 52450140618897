import React                  from 'react';
import PropTypes              from 'prop-types';
import OpenMessengerWrapper   from './ContactContainer/OpenMessengerWrapper';
import PinActorButtonWrapper  from './ContactContainer/PinActorButtonWrapper';
import Flux                   from '../../../flux/Flux';
import VideochatButtonWrapper from '../../SimpleElements/VideochatButtonWrapper';

class ActorContactContainer extends React.PureComponent {
    render() {
        if (!this.props.show) {
            return null;
        }

        const btns = [
            <PinActorButtonWrapper
                    key={1}
                    show={!this.props.isOnline && !this.props.isOnlineMobileVideocall}
                    actorId={this.props.actorId}
                    isLoggedIn={this.props.isGuestLoggedIn}
                    isPinned={this.props.isActorPinned}
            />,
            <OpenMessengerWrapper
                    key={2}
                    show={!this.props.isOnline || !this.props.hasLivePreview}
                    openMessenger={this.props.openMessenger}
            />,
            <VideochatButtonWrapper type={Flux.Constants.VideochatButtonTypes.actorProfileLivePreviewSmall} actorId={this.props.actorId} />,
            <VideochatButtonWrapper type={Flux.Constants.VideochatButtonTypes.actorTile} actorId={this.props.actorId} />,
        ];

        return (
                <div className="actorcontact__container">
                    <div className="actorcontact">
                        <div className="actorcontact__text">
                            <span className="actorcontact__text-actorname">{this.props.sublineText}</span>
                        </div>
                        <div className={"actorcontact__container--btn-wrapper" + (this.props.isOnlineMobileVideocall ? " actorcontact__container--btn-wrapper--margin" : "")}>
                            {btns}
                        </div>
                    </div>
                </div>
        );
    }
}

ActorContactContainer.propTypes = {
    show:                    PropTypes.bool,
    isOnline:                PropTypes.bool,
    isGuestLoggedIn:         PropTypes.bool,
    isActorPinned:           PropTypes.bool,
    hasLivePreview:          PropTypes.bool,
    sublineText:             PropTypes.string,
    multiChatPrice:          PropTypes.number,
    singleC2CChatPrice:      PropTypes.number,
    actorId:                 PropTypes.number,
    openMessenger:           PropTypes.func,
    isOnlineMobileVideocall: PropTypes.bool,
};

ActorContactContainer.defaultProps = {
    show:                    false,
    isOnline:                false,
    isGuestLoggedIn:         false,
    isActorPinned:           false,
    sublineText:             '',
    isOnlineMobileVideocall: false,
};

export default ActorContactContainer;
