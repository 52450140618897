import React     from 'react';
import PropTypes from 'prop-types';
import { generateKey } from '../../utils/CommonUtils';

class ActorPlateLight extends React.Component {

	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="context-box--media-box__plate -light -height-xs">
				<ul className="h-list--horizontal">
					<li key={generateKey(this.props.name)} className="context-box--media-box__list__item h-text-align-center h-width-full h-over-ellipsis">
						{React.createElement(
							this.props.headlineType,
							{className: 'actor-name -size-sm h-text-normal'},
							this.props.name,
						)}
						<span className="actor-age -smooth -size-sm h-text-normal">({this.props.age})</span>
					</li>
				</ul>
			</div>
		);
	}
}

ActorPlateLight.propTypes = {
	name:         PropTypes.string.isRequired,
	age:          PropTypes.number.isRequired,
	headlineType: PropTypes.string,
};

ActorPlateLight.defaultProps = {
	headlineType: 'h3',
};

export default ActorPlateLight;
