import React                from 'react';
import PropTypes            from 'prop-types';
import Flux                 from '../../../flux/Flux';
import Translations         from '../../../utils/Translations';
import {VXPay, VXPayAction} from '../../../utils/VXPay';


class Button extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			isFavoriteOfGuest: this.props.initialIsFavoriteOfGuest,
		};

		this.onActorAdded    = this.onActorAdded.bind(this);
		this.onActorRemoved  = this.onActorRemoved.bind(this);
		this.onButtonClicked = this.onButtonClicked.bind(this);
		this.togglePin       = this.togglePin.bind(this);
	}

	componentDidMount() {
		Flux.Favorite.addActorPinnedListener(this.onActorAdded);
		Flux.Favorite.addActorUnpinnedListener(this.onActorRemoved);
	}

	componentWillUnmount() {
		Flux.Favorite.removeActorPinnedListener(this.onActorAdded);
		Flux.Favorite.removeActorUnpinnedListener(this.onActorRemoved);
	}

	onActorAdded(actorId) {
		if (actorId === this.props.actorId) {
			this.setState({
				isFavoriteOfGuest: true,
			});
		}
	}

	onActorRemoved(actorId) {
		if (actorId === this.props.actorId) {
			this.setState({
				isFavoriteOfGuest: false,
			});
		}
	}

	togglePin() {
		if (this.state.isFavoriteOfGuest) {
			Flux.Favorite.unpinActor(this.props.actorId);
		} else {
			Flux.Favorite.pinActor(this.props.actorId);
		}
		this.setState({
			isFavoriteOfGuest: !this.state.isFavoriteOfGuest,
		});
	}

	onButtonClicked(e) {
		if (typeof this.props.onClickCallback === 'function') {
			this.props.onClickCallback();
		} else if (!this.props.guestLoggedIn) {
			const action = new VXPayAction(Flux.Constants.ActionTypes.Notification.FAVORITE_PIN_ACTOR, {
				targetId: this.props.actorId,
			});
			VXPay.openSignupOrLogin({
				host: this.props.actorId,
			}, action);
		} else {
			this.togglePin();
		}
		if (e.preventDefault) {
			e.preventDefault();
		}
	}

	render() {
		let buttonClass, text1, subline;
		if (this.state.isFavoriteOfGuest) {
			buttonClass = 'btn--icon-detailed -icon-heart-full -btn-color-disabled -click-material';
			text1       = Translations.get('FavoriteActorButtonPinnedText1');
			subline     = Translations.get('FavoriteActorButtonPinnedSubline');
		} else {
			buttonClass = 'btn--icon-detailed -icon-heart-line -click-material';
			text1       = Translations.get('FavoriteActorButtonUnpinnedText1');
			subline     = Translations.get('FavoriteActorButtonUnpinnedSubline');
		}

		if (this.props.cssButtonModifier) {
			buttonClass += " " + this.props.cssButtonModifier;
		}

		return this.props.isTextWrapped
			? (
				<button className={buttonClass}
				        onClick={this.onButtonClicked}
				>
					<span className='btn__text-container'>
						<span>{text1}</span>
						<span className="btn--icon-detailed__teaser h-text-nowrap">{subline}</span>
					</span>
				</button>
			) : (
				<button className={buttonClass}
				        onClick={this.onButtonClicked}
				>
					<span>{text1}</span>
					<span className="btn--icon-detailed__teaser h-text-nowrap">{subline}</span>
				</button>
			);
	}
}

Button.propTypes = {
	actorId:                  PropTypes.number.isRequired,
	guestLoggedIn:            PropTypes.bool.isRequired,
	initialIsFavoriteOfGuest: PropTypes.bool.isRequired,
	isTextWrapped:            PropTypes.bool,
	onClickCallback:          PropTypes.func,
	cssButtonModifier:        PropTypes.string,
};

Button.defaultProps = {
	isTextWrapped: false,
};

export default Button;
