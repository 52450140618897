import React     from 'react';
import PropTypes from 'prop-types';

class ActorBirthdayPlate extends React.PureComponent {

	render() {
		return (
			<div className="context-box--media-box__plate h-clear -actor-birthday-plate">
				<div className="context-box--media-box__custombox -top3box">
					<div className="h-height-full">
						<div className="context-box--media-box__custombox -actorelements h-left">
							{this.props.name} <span className="context-box--media-panel__actor--age">({this.props.age})</span>
						</div>
						<div className="context-box--media-box__custombox -description h-left">
							<span className="icon-actor -icon-fact-birthday" /> {this.props.birthdayText}
						</div>
					</div>
				</div>
			</div> 
		);
	}
}

ActorBirthdayPlate.propTypes = {
	name:         PropTypes.string.isRequired,
	age:          PropTypes.number.isRequired,
	birthdayText: PropTypes.string.isRequired,
};

export default ActorBirthdayPlate;