import React                  from 'react';
import PropTypes              from 'prop-types';
import {getCommonTranslation} from '../../../utils/TranslationHelper';

const CLASS_NAME = 'badge--success tile-badge tile-badge-live -non-hover-overlay';

class TileBadgeLive extends React.PureComponent {
	render() {
		if (!this.props.show) {
			return null;
		}

		return <span className={CLASS_NAME + ' ' + this.props.className}>{getCommonTranslation('live-badge')}</span>;
	}
}

TileBadgeLive.propTypes    = {
	show:      PropTypes.bool,
	className: PropTypes.string,
};
TileBadgeLive.defaultProps = {
	className: '',
};


export default TileBadgeLive;
