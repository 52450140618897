import React        from 'react';
import PropTypes    from 'prop-types';
import Translations from '../../../utils/Translations';

const CLASS_NAME = 'badge--normal tile-badge tile-badge-live-preview';

class TileBadgeLivePreview extends React.PureComponent {
	render() {
		if (!this.props.show) {
			return null;
		}

		return <span className={CLASS_NAME}>{Translations.get('BadgeLivePreview')}</span>;
	}
}

TileBadgeLivePreview.propTypes = {
	show: PropTypes.bool.isRequired,
};

TileBadgeLivePreview.defaultProps = {
	show: false,
};

export default TileBadgeLivePreview;
