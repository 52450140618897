import React        from 'react';
import PropTypes    from 'prop-types';
import Translations from "../../utils/Translations";

class VideoStarPlate extends React.Component {

	/**
	 *
	 * @param {string} value
	 * @returns {string}
	 */
	getFirstLetterLowerCase(value) {
		return value.charAt(0).toLowerCase() + value.slice(1);
	}

	render() {
		const nameplate = <div className={"video-stars-plate-name"}>{this.props.actorName} ({this.props.actorAge})</div>;

		const newVideosText = this.props.newVideosCount + " " + (this.props.newVideosCount === 1 ? this.getFirstLetterLowerCase(Translations.get('ActorProfileNewVideo')) : this.getFirstLetterLowerCase(Translations.get('VideoGridNewestTitle')));
		const videosText    = this.props.videosCount + (this.props.videosCount === 1 ? " Video" : " Videos");

		const videoCounts = (
			<div className={"video-stars-plate-counts"}>
				<span className={"video-stars-plate-videoCount"}>{videosText}</span>
				{this.props.newVideosCount > 0 ? ' - ' : ''}
				{this.props.newVideosCount > 0 &&
				<span className={"video-stars-plate-newVideoCount"}>{newVideosText}</span>
				}
			</div>
		);

		return (
			<div className="video-stars-plate">
				{nameplate}
				{videoCounts}
			</div>
		);
	}
}

VideoStarPlate.propTypes = {
	videosCount:    PropTypes.number,
	newVideosCount: PropTypes.number,
	actorName:      PropTypes.string.isRequired,
	actorAge:       PropTypes.string.isRequired,
};

VideoStarPlate.defaultProps = {
	videosCount:    0,
	newVideosCount: 0,
};

export default VideoStarPlate;