import React            from 'react';
import PropTypes        from 'prop-types';

const CLASS_NAME = 'badge--normal tile-badge tile-badge-hd';

class TileBadgeHD extends React.PureComponent {
	render() {
		if (!this.props.show) {
			return null;
		}

		return <span className={CLASS_NAME}>HD</span>;
	}
}

TileBadgeHD.propTypes = {
	show: PropTypes.bool.isRequired,
};

TileBadgeHD.defaultProps = {
	show: false,
};

export default TileBadgeHD;
