import React     from 'react';
import PropTypes from 'prop-types';
import Button    from '../../../Favorite/Actor/Button';

class PinActorButtonWrapper extends React.PureComponent {
	render() {
		if (!this.props.show) {
			return null;
		}

		return (
			<Button
				actorId={this.props.actorId}
				guestLoggedIn={this.props.isLoggedIn}
				initialIsFavoriteOfGuest={this.props.isPinned}
				cssButtonModifier=' -size-sm'
				isTextWrapped={true}
			/>
		);
	}
}

PinActorButtonWrapper.propTypes = {
	show:       PropTypes.bool,
	isPinned:   PropTypes.bool,
	isLoggedIn: PropTypes.bool,
	actorId:    PropTypes.number,
};

export default PinActorButtonWrapper;
