import React                 from 'react';
import PropTypes             from 'prop-types';
import Translations          from '../../../../utils/Translations';

class OpenMessengerWrapper extends React.Component {
	render() {
		if (!this.props.show) {
			return null;
		}

		const cssClass = "btn--icon-detailed -btn-color- -icon-chat-text-full -click-material -size-sm";

		return (
			<button className={cssClass} onClick={this.props.openMessenger}>
				<span className='btn__text-container'>
					<span className="ink animate" />
					{Translations.get('ActorGridMessageText')}
					<span className="btn--icon-detailed__teaser h-text-nowrap">{Translations.get('ActorGridWriteFreeText')}</span>
				</span>
			</button>
		);
	}
}

OpenMessengerWrapper.propTypes = {
	show:            PropTypes.bool,
	openMessenger:   PropTypes.func,
};

export default OpenMessengerWrapper;
